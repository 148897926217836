<template>
  <div>
    <div
      v-if="mode != 'newPayment'"
      class="flex flex-col md:flex-row justify-between  items-center  mb-4 mt-3"
    >
      <div class="text-center mb-2">
        <button
          @click="ChangeMode('invoice')"
          class="pl-3 pr-3 border-2 mb-2 md:mb-0 md:text-xs border-darkblue hover:bg-green-400 hover:border-green-400 pt-1 pb-1 rounded-full"
          :class="{
            'bg-darkblue text-white hover:text-black': mode == 'invoice',
            'text-darkblue  ': mode != 'invoice',
          }"
          type="button"
        >
          Invoice
        </button>
        <button
          type="button"
          @click="ChangeMode('payment')"
          :class="{
            'bg-darkblue text-white hover:text-black': mode == 'payment',
            'text-darkblue  ': mode != 'payment',
          }"
          class="ml-2 mr-2 mb-2 md:text-xs md:mb-0 border-2 border-darkblue hover:bg-green-400 hover:border-green-400  pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Payment
        </button>
        <button
          v-if="role.toLowerCase() == 'admin'"
          type="button"
          @click="ChangeMode('profit')"
          :class="{
            'bg-darkblue text-white hover:text-black': mode == 'profit',
            'text-darkblue  ': mode != 'profit',
          }"
          class=" md:text-xs  mb-2 md:mb-0 border-2 border-darkblue hover:bg-green-400 hover:border-green-400  pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Profit
        </button>
      </div>
      <div class="text-center">
        <button
          @click="Print"
          type="button"
          class=" mb-2 bg-green-600 hover:border-darkblue border-green-600  text-white hover:bg-darkblue  md:text-xs md:mb-0 border-2    pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Print Page
        </button>
        <button
          @click="mode = 'newPayment'"
          type="button"
          class=" mb-2 ml-2 mr-2 bg-green-600 hover:border-darkblue border-green-600  text-white hover:bg-darkblue  md:text-xs md:mb-0 border-2    pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Add Payment
        </button>
        <button
          @click="showDeleteModal = true"
          type="button"
          class=" md:text-xs mb-2 md:mb-0  border-2 bg-red-600 border-red-600 text-white hover:text-black hover:bg-white hover:border-white  pl-3 pr-3 pt-1 pb-1 rounded-full"
        >
          Delete Invoice
        </button>
      </div>
    </div>
    <div v-if="loading.invoice" class="flex justify-center flex-col mb-2">
      <div>
        <p class="text-red-700 text-center md:text-xs font-bold  ">
          Loading,please wait...
        </p>
      </div>
      <div class="mt-2">
        <svg
          class="ml-auto mr-auto animate-spin"
          width="25"
          height="25"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M48 25C48 12.2975 37.7025 2 25 2"
            stroke="black"
            stroke-width="4"
          />
          <path
            d="M48 25C48 37.7025 37.7025 48 25 48"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M2 25C2 12.2975 12.2975 2 25 2"
            stroke="white"
            stroke-width="4"
          />
          <path
            d="M25 48C12.2975 48 2 37.7025 2 25"
            stroke="#C9000C"
            stroke-width="4"
          />
        </svg>
      </div>
    </div>
    <div
      class="md:w-8/12 mt-4 mb-4 md:mt-0 w-full mx-auto bg-white pl-2 pr-2 md:pl-0 md:pr-0 pt-4 pb-4 rounded-lg"
      v-if="mode == 'newPayment'"
    >
      <h5
        v-if="mode != 'invoice'"
        class=" text-center text-darkblue font-semibold mb-2"
      >
        Add Payment
      </h5>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form
          class="md:pl-20 md:pr-20"
          @submit.prevent="handleSubmit(AddPayment)"
        >
          <div class=" mb-2">
            <label class="block md:text-xs text-darkblue" for="Customer's name">
              Amount(₦)
            </label>
            <validation-provider
              rules="required|numeric|min_value:1"
              v-slot="{ errors }"
            >
              <input
                type="text"
                v-model="newPayment.amount"
                name="Amount"
                class="border-2 md:text-xs text-darkblue md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class=" mb-2">
            <label class="block md:text-xs text-darkblue" for="Customer's name">
              Payment Method(₦)
            </label>
            <validation-provider rules="required" v-slot="{ errors }">
              <select
                v-model="newPayment.method"
                class="w-full border-2 border-darkblue rounded-lg md:text-xs text-darkblue"
                name="method"
                id=""
              >
                <option value="" selected>Select Method</option>
                <option value="cash">Cash</option>
                <option value="cheque">Cheque</option>
                <option value="transfer">Transfer</option>
                <option value="p.o.s">P.O.S</option>
              </select>

              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class=" mb-2">
            <label class="block md:text-xs text-darkblue" for="Customer's name">
              Identifier
            </label>
            <validation-provider rules="custom_text" v-slot="{ errors }">
              <input
                type="text"
                v-model="newPayment.identifier"
                name="Amount"
                class="border-2 md:text-xs text-darkblue md:w- w-full border-darkblue rounded-lg block"
              />
              <span class="text-red-700 md:text-xs">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="text-center mt-3">
            <button
              @click="mode = 'invoice'"
              type="button"
              class="text-white inline-block mr-1 md:text-xs bg-red-700 hover:text-white hover:bg-black pl-3 pt-2 pb-2 pr-3  rounded-full"
            >
              Back to Invoice
            </button>
            <button
              type="submit"
              class="text-white ml-1 inline-block md:text-xs bg-green-700  hover:bg-darkblue pl-3 pt-2 pb-2 pr-3  rounded-full"
            >
              <svg
                v-if="loading.payment"
                class="inline animate-spin mr-2"
                width="25"
                height="25"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48 25C48 12.2975 37.7025 2 25 2"
                  stroke="black"
                  stroke-width="4"
                />
                <path
                  d="M48 25C48 37.7025 37.7025 48 25 48"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M2 25C2 12.2975 12.2975 2 25 2"
                  stroke="white"
                  stroke-width="4"
                />
                <path
                  d="M25 48C12.2975 48 2 37.7025 2 25"
                  stroke="#C9000C"
                  stroke-width="4"
                />
              </svg>
              Add payment
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <div
      id="fortunatePrint"
      v-if="mode != 'newPayment'"
      class="bg-white p-3 md:p-6 rounded-lg"
    >
      <LetterHead :class="'letterHeadParent'" :data="letter_head_data" />

      <div class="printClear">
        <div class="mt-6 overflow-x-auto">
          <h5
            v-if="mode != 'invoice'"
            class=" text-center text-darkblue font-semibold mb-2"
          >
            {{ mode == "payment" ? "Payments" : "Profit" }}
          </h5>
          <t-table
            id="my-table"
            :classes="table_classes"
            :headers="tableHeader"
            :data="tableData"
          >
            <template slot="column" slot-scope="props">
              <td v-if="props.text === 'action'" :class="props.tdClass">
                <button
                  @click="DeletePayment(props)"
                  class="text-white font-bold   md:pl-3 pl-2 pr-2 md:pr-3 pt-2 pb-2 rounded-lg"
                >
                  <font-awesome-icon
                    title="Delete"
                    :class="'text-red-600 hover:text-black text-sm'"
                    :icon="['far', 'trash-alt']"
                  />
                </button>
              </td>
              <td v-else :class="props.tdClass">{{ props.text }}</td>
            </template>
          </t-table>
        </div>

        <div
          v-if="loading.invoice"
          class="flex mt-2 justify-center flex-col mb-2"
        >
          <div>
            <p class="text-red-700 text-center md:text-xs font-bold  ">
              Loading,please wait...
            </p>
          </div>
          <div class="mt-2">
            <svg
              class="ml-auto mr-auto animate-spin"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48 25C48 12.2975 37.7025 2 25 2"
                stroke="black"
                stroke-width="4"
              />
              <path
                d="M48 25C48 37.7025 37.7025 48 25 48"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M2 25C2 12.2975 12.2975 2 25 2"
                stroke="white"
                stroke-width="4"
              />
              <path
                d="M25 48C12.2975 48 2 37.7025 2 25"
                stroke="#C9000C"
                stroke-width="4"
              />
            </svg>
          </div>
        </div>
        <p
          v-if="tableData.length < 1 && loading.invoice != true"
          class=" mt-2 text-center md:text-sm"
        >
          No data available to display
        </p>
      </div>

      <div class="text-center mt-6">
        <p class="inline-block mr-4 text-sm">
          <span class="text-darkblue font-bold ">VAT Percentage :</span> 0%
        </p>
        <p class="inline-block text-sm">
          <span class="text-darkblue font-bold">VAT Amount :</span> ₦0
        </p>
      </div>
      <div v-if="mode === 'profit'" class="text-center mt-2">
        <p class="inline-block mr-4 text-sm">
          <span class="text-darkblue font-bold "
            >Total Invoice Cost Price :</span
          >
          ₦{{ invoiceTotalCostPrice.toLocaleString() }}
        </p>
        <p class="inline-block text-sm">
          <span class="text-darkblue font-bold">Total Invoice Profit :</span>
          ₦{{ totalInvoiceProfit.toLocaleString() }}
        </p>
      </div>

      <p class="text-sm text-center mt-2">
        <span class="text-darkblue font-bold"> Invoice Total :</span> ₦{{
          !!invoice.invoiceTotal ? invoice.invoiceTotal.toLocaleString() : 0
        }}
      </p>
    </div>
    <div>
      <transition name="slide-fade">
        <NotificationModal
          v-if="showDeleteModal"
          :modalData="modalData"
          @modal-success="DeleteInvoice($event)"
          @modal-close="CloseModal($event)"
        />
      </transition>
    </div>
  </div>
</template>

<script>
  import gql from "graphql-tag";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import NotificationModal from "../../components/NotificationModal.vue";
  import { printMixin } from "@/assets/helpers/printMixin";
  import { mapState, mapMutations } from "vuex";

  import LetterHead from "../../components/LetterHead.vue";
  export default {
    name: "SingleInvoice",
    props: {},
    mixins: [printMixin],

    components: {
      LetterHead,
      ValidationProvider,
      ValidationObserver,
      FontAwesomeIcon,
      NotificationModal,
    },
    apollo: {
      invoice: {
        query: gql`
          query invoice($id: ID!) {
            invoice(id: $id) {
              serialId
              customerId {
                customerName
                address
                phoneNumber
                email
              }
              physicalInvoiceNo
              createdAt
              authorId {
                _id
                name
              }
              totalCostPrice
              invoiceTotal
              completedPayment
              payments {
                _id
                amount
                method
                identifier
                regDate
              }
              invoiceItems {
                quantity
                unitPrice
                stockId {
                  itemName
                  unit
                }
                quantityBreakdown {
                  purchasedItemId {
                    unitPrice
                  }
                  quantityRemoved
                }
                itemTotal
                totalCostPrice
              }
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },
        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loading.invoice = true;
            return;
          }

          this.loading.invoice = false;
        },
      },
    },
    data() {
      return {
        showDeleteModal: false,
        modalData: {
          title: "Delete Invoice",
          message:
            "Are you sure you want to delete this invoice? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },

        invoice: {
          authorId: {
            name: "",
          },
          invoiceItems: [],
        },
        amountPaid: 0,
        balance: 0,
        newPayment: {
          amount: 0,
          method: "",
          identifier: "",
        },
        loading: {
          invoice: false,
          payment: false,
        },
        letter_head_data: {
          left_side: [],
          right_side: [],
          letter_head_title: "",
        },
        invoiceTotalCostPrice: 0,
        totalInvoiceProfit: 0,
        select_component_classes:
          "block w-full pl-3 pr-10 py-2 text-darkblue placeholder-gray-400 transition duration-100 ease-in-out bg-white border-2 border-darkblue rounded-lg  shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",

        mode: "invoice",
        rich_select_default_component_classes: {
          wrapper: "",
          buttonWrapper: "",
          selectButton:
            "px-3 rounded-lg py-2 text-black transition duration-100 ease-in-out bg-white border-darkblue border-2 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          selectButtonLabel: "",
          selectButtonPlaceholder: "text-darkblue",
          selectButtonIcon: "text-gray-600",
          selectButtonClearButton:
            "hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out",
          selectButtonClearIcon: "",
          dropdown:
            "-mt-1 bg-white border-b border-gray-300 border-l border-r rounded-b shadow-sm",
          dropdownFeedback: "pb-2 px-3 text-gray-400 text-sm",
          loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
          optionsList: "",
          searchWrapper: "p-2 placeholder-gray-400",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300",
          optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
          option: "",
          disabledOption: "",
          highlightedOption: "bg-blue-100",
          selectedOption:
            "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
          selectedHighlightedOption:
            "font-semibold bg-gray-100 bg-darkblue font-semibold text-white",
          optionContent: "flex justify-between items-center px-3 py-2",
          optionLabel: "",
          selectedIcon: "",
          enterClass: "",
          enterActiveClass: "opacity-0 transition ease-out duration-100",
          enterToClass: "opacity-100",
          leaveClass: "transition ease-in opacity-100",
          leaveActiveClass: "",
          leaveToClass: "opacity-0 duration-75",
        },
        table_classes: {
          table:
            "min-w-full text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-transparent border",
          thead: "border-b-2 border-darkblue md:text-xs ",
          theadTr: "bg-darkblue text-xs text-white rounded-t-lg",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-darkblue",
          tr: "rounded-lg text-xs md:text-base",
          td: "px-3 py-2 whitespace-no-wrap md:text-xs",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        rich_select_component_classes: {
          wrapper: "relative",
          buttonWrapper: "inline-block relative w-full",
          selectButton: "w-full flex text-left justify-between items-center",
          selectButtonLabel: "block truncate",
          selectButtonPlaceholder: "block truncate",
          selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
          selectButtonClearButton:
            "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
          selectButtonClearIcon: "fill-current h-3 w-3",
          dropdown: "absolute w-full z-10",
          dropdownFeedback: "",
          loadingMoreResults: "",
          optionsList: "overflow-auto",
          searchWrapper: "inline-block w-full",
          searchBox: "inline-block w-full",
          optgroup: "",
          option: "cursor-pointer",
          disabledOption: "opacity-50 cursor-not-allowed",
          highlightedOption: "cursor-pointer",
          selectedOption: "cursor-pointer",
          selectedHighlightedOption: "cursor-pointer",
          optionContent: "",
          optionLabel: "truncate block",
          selectedIcon: "fill-current h-4 w-4",
          enterClass: "",
          enterActiveClass: "",
          enterToClass: "",
          leaveClass: "",
          leaveActiveClass: "",
          leaveToClass: "",
        },

        tableHeader: [
          {
            id: "stockName",
            value: "stockName",
            text: "Item Name",
            className: "",
          },
          {
            value: "quantity",
            text: "Quantity",
            className: "fortunate-table",
          },
          {
            value: "unit",
            text: "Unit",
            className: "fortunate-table",
          },
          {
            value: "rate",
            text: "Rate(₦)",
            className: "fortunate-table",
          },
          {
            value: "total",
            text: "Total(₦)",
            className: "fortunate-table",
          },
        ],

        paymentData: [],
        invoiceData: [],
        profitData: [],
        tableData: [],
      };
    },
    computed: {
      ...mapState({
        role: "role",
      }),
    },
    watch: {
      invoice: {
        handler: function(newValue) {
          let date = new Date(Number(newValue.createdAt));

          date = `${date.getDate()}/${date.getMonth() +
            1}/${date.getFullYear()}`;

          let amountPaid = 0;
          newValue.payments.forEach((payment) => {
            amountPaid += Number(payment.amount);
          });
          this.GeneratePaymentTable(newValue.payments);
          this.GenerateInvoiceTable(newValue);
          this.tableData = this.invoiceData;
          this.GenerateProfitTable(newValue);

          this.amountPaid = amountPaid;
          this.balance = Number(newValue.invoiceTotal) - Number(amountPaid);
          this.letter_head_data = {
            left_side: [
              {
                label: "CUSTOMERS NAME",
                value: newValue.customerId.customerName,
              },
              {
                label: "PHONE NO",
                value: newValue.customerId.phoneNumber,
              },
              {
                label: "ADDRESS",
                value: newValue.customerId.address,
              },
              {
                label: "DATE",
                value: date,
              },
            ],
            right_side: [
              {
                label: "PHYSICAL INVOICE NO",
                value: newValue.physicalInvoiceNo,
              },
              {
                label: "CREATED BY",
                value: newValue.authorId.name,
              },
              {
                label: "PAYMENT STATUS",
                value: newValue.completedPayment == true ? "paid" : "pending",
              },
              {
                label: "AMOUNT PAID",
                value: `₦ ${amountPaid.toLocaleString()}`,
              },
              {
                label: "BALANCE",
                value: `₦ ${this.balance.toLocaleString()}`,
              },
            ],
            letter_head_title: `INVOICE ${newValue.serialId}`,
          };
        },
        deep: true,
      },
      mode: function(mode) {
        switch (mode) {
          case "profit":
            this.tableHeader = [
              {
                value: "itemName",
                text: "Item Name",
                className: "",
              },

              {
                value: "unitSellingPrice",
                text: "Unit Price(₦)",
                className: "",
              },
              {
                value: "quantity",
                text: "Quantity",
                className: "",
              },
              {
                value: "unit",
                text: "Unit",
                className: "",
              },
              {
                value: "totalCostPrice",
                text: "Total Cost Price(₦)",
                className: "",
              },
              {
                value: "itemTotal",
                text: "Item Total(₦)",
                className: "",
              },
              {
                value: "profit",
                text: "Profit(₦)",
                className: "",
              },
            ];

            this.tableData = this.profitData;

            break;
          case "payment":
            this.tableHeader = [
              {
                value: "amount",
                text: "Amount(₦)",
                className: "",
              },
              {
                value: "method",
                text: "Method",
                className: "fortunate-table",
              },
              {
                value: "identifier",
                text: "Identifier",
                className: "fortunate-table",
              },
              {
                value: "regDate",
                text: "Reg Date",
                className: "fortunate-table",
              },
              {
                value: "action",
                text: "Delete",
                className: "fortunate-table",
              },
            ];
            this.tableData = this.paymentData;

            break;
          case "invoice":
            this.tableHeader = [
              {
                id: "stockName",
                value: "stockName",
                text: "Item Name",
                className: "",
              },
              {
                value: "quantity",
                text: "Quantity",
                className: "fortunate-table",
              },
              {
                value: "unit",
                text: "Unit",
                className: "fortunate-table",
              },
              {
                value: "rate",
                text: "Rate(₦)",
                className: "fortunate-table",
              },
              {
                value: "total",
                text: "Total(₦)",
                className: "fortunate-table",
              },
            ];
            this.tableData = this.invoiceData;

            break;

          default:
            break;
        }
      },
    },
    methods: {
      ...mapMutations(["setBreadcrumbs"]),

      async DeleteInvoice() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!) {
                deleteInvoice(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteInvoice._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Invoice deleted successfully`,
            });

            return this.$router.push("/app/invoices");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting invoice,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting invoice,check your network or contact your developer`,
          });
        }
      },
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },
      TableButton() {},
      async AddPayment() {
        if (Number(this.newPayment.amount) > Number(this.balance)) {
          return this.$emit("show-alert", {
            type: "error",
            message: `The amount you want to pay is greater than the invoice balance ${this.balance}`,
          });
        }
        this.newPayment.amount = Number(this.newPayment.amount);
        this.loading.payment = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($invoiceId: ID!, $payment: InvoicePaymentInput!) {
                createPayment(invoiceId: $invoiceId, payment: $payment) {
                  _id
                  amount
                  method
                  regDate
                }
              }
            `,
            // Parameters
            variables: {
              invoiceId: this.$route.params.id,
              payment: this.newPayment,
            },
          });

          this.loading.payment = false;
          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }
          if (!errors && !!data.createPayment) {
            this.$emit("show-alert", {
              type: "success",
              message: "Payment created successfully",
            });
            await this.$apollo.queries.invoice.refetch();
            this.mode = "invoice";
            return;
          }
          this.$emit("show-alert", {
            type: "error",
            message: `Error creating payment,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.payment = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating payment,check your network or contact your developer`,
          });
        }
      },
      async DeletePayment(row) {
        const paymentId = this.invoice.payments[row.rowIndex]._id;

        this.loading.invoice = true;
        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($invoiceId: ID!, $paymentId: ID!) {
                deletePayment(invoiceId: $invoiceId, paymentId: $paymentId) {
                  _id
                  amount
                  method
                  identifier
                  regDate
                }
              }
            `,
            // Parameters
            variables: {
              invoiceId: this.$route.params.id,
              paymentId: paymentId,
            },
          });

          this.loading.invoice = false;
          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }
          if (!errors && !!data.deletePayment) {
            this.$emit("show-alert", {
              type: "success",
              message: "Payment deleted successfully",
            });
            await this.$apollo.queries.invoice.refetch();

            this.mode = "invoice";
            return;
          }
          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting payment,check your network or contact your developer`,
          });
        } catch (error) {
          this.loading.invoice = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting payment,check your network or contact your developer`,
          });
        }
      },
      GenerateInvoiceTable(newValue) {
        this.invoiceData = newValue.invoiceItems.map((invoiceItem) => {
          return {
            stockName: invoiceItem.stockId.itemName,
            quantity: invoiceItem.quantity,
            unit: invoiceItem.stockId.unit,
            rate: invoiceItem.unitPrice.toLocaleString(),
            total: invoiceItem.itemTotal.toLocaleString(),
          };
        });
      },
      GeneratePaymentTable(payments) {
        this.paymentData = payments.map((payment) => {
          let date = new Date(Number(payment.regDate));

          date = `${date.getDate()}/${date.getMonth() +
            1}/${date.getFullYear()}`;

          return {
            _id: payment._id,

            amount: payment.amount.toLocaleString(),
            identifier: payment.identifier,
            method: payment.method,
            regDate: date,
            action: "action",
          };
        });
      },

      GenerateProfitTable(newValue) {
        this.profitData = newValue.invoiceItems.map((invoiceItem) => {
          let totalCostPrice = 0;

          invoiceItem.quantityBreakdown.forEach((quantityBreakdown) => {
            totalCostPrice +=
              Number(quantityBreakdown.purchasedItemId.unitPrice) *
              Number(quantityBreakdown.quantityRemoved);
          });
          let profit = Number(invoiceItem.itemTotal) - Number(totalCostPrice);
          this.totalInvoiceProfit += profit;

          this.invoiceTotalCostPrice += totalCostPrice;

          return {
            itemName: invoiceItem.stockId.itemName,
            unitSellingPrice: invoiceItem.unitPrice.toLocaleString(),
            totalCostPrice: totalCostPrice.toLocaleString(),
            itemTotal: invoiceItem.itemTotal.toLocaleString(),
            quantity: invoiceItem.quantity,
            unit: invoiceItem.stockId.unit,
            profit: profit.toLocaleString(),
          };
        });
      },
      ToggleChartSpinner() {
        this.chart_loading = !this.chart_loading;
      },

      ChangeMode(mode) {
        this.mode = mode;
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Invoices", pathName: "invoices" },
        {
          displayName: "Single Invoice",
          pathName: "single_invoice",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
